
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.category-tree {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  @include for-size(phone-portrait-down) {
    margin-bottom: 12px;
  }
  &__head {
    margin-bottom: 16px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include for-size(phone-portrait-down) {
      flex-direction: column;
    }
  }

  &__item {
    width: calc(50% - 12px);
    margin-bottom: 24px;

    @include for-size(phone-portrait-down) {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  .esmp-select-disabled {
    opacity: .5;
    .esmp-select-head {
      background-color: $color-grayscale-40;
    }
  }

  .esmp-select-item {
    white-space: unset;
  }

  &__view {
    display: flex;
    justify-content: flex-end;
    margin-bottom: $base-gutter;
    color: $color-black;
    transition: color $base-animation-time;
    margin-top: -45px;

    @include for-size(phone-portrait-down) {
      margin-top: 0;
    }
  }
  &__view-item {
    & + & {
      margin-left: $base-gutter;
    }

    &:hover,
    &--active {
      color: $primary-color-hover;
      cursor: pointer;
    }
  }
  &__cards-collapser {
    margin-bottom: $base-gutter;
  }
  &__cards {
    display: grid;
    gap: $gap / 2;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: max-content;
    margin-bottom: $base-gutter;

    @include for-size(tablet-landscape-down) {
      grid-template-columns: 1fr 1fr;
    }

    @include for-size(phone-landscape-down) {
      grid-template-columns: 100%;
      gap: $gap / 2;
    }
  }

  &__card {
    border-radius: $base-border-radius * 2;
    padding: $gap / 2;
    border: 1px solid $primary-color;
    cursor: pointer;
    display: flex;
    gap: $base-gutter;
    align-items: flex-start;

    @include for-size(phone-portrait-down) {
      padding: 16px;
      min-height: 152px;
      .esmp-svg-icon {
        width: 48px;
        height: 48px;
        flex-direction: column-reverse;
      }
    }
    &:hover {
      transition: box-shadow $base-animation;
      box-shadow: $base-shadow-m;
    }
    &--active {
      background-color: rgba(var(--esmp-ui-primary-color-rgb), 0.12);
    }
  }
  &__card-title {
    flex-grow: 1;
    padding-top: 4px;
    color: $color-text;
    font-weight: 700;
    @include for-size(phone-portrait-down) {
      font-size: 14px;
      padding: 0;
      margin-top: 12px;
    }
  }
}
